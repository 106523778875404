import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./TallaModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

Modal.setAppElement("#root");

const TallaModal = ({
  isOpen,
  onRequestClose,
  selectedProduct,
  selectedTalla,
  setSelectedTalla,
  handleConfirmAddToCart,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="Modal"
      overlayClassName="Overlay"
    >
      {isLoading ? (
        <div className="loader">Cargando...</div>
      ) : (
        <div className="modal-content">
          <div className="modal-header">
            <h2>Compartir este link</h2>
            <button className="close-button" onClick={onRequestClose}>
              ×
            </button>
          </div>
          <div className="modal-body">
              <div className="container">
                <button
                  data-testid="UrlClipboardCopy"
                  className="share-on-socials__copy-to-clipboard-button"
                  style={{ width: "92%", height: "60px" }}
                >
                  <div className="inner-container">
                    <div data-testid="UrlPreview" className="url-preview">
                      <div className="icon-container">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          height="25"
                          style={{ display: "block", width: "auto" }}
                        >
                          <path
                            d="M13.5108 5.85343L17.5158 1.73642L19.8404 4.11701L15.6393 8.12199H21.5488V11.4268H15.6113L19.8404 15.5345L17.5158 17.8684L11.7744 12.099L6.03299 17.8684L3.70842 15.5438L7.93745 11.4361H2V8.12199H7.90944L3.70842 4.11701L6.03299 1.73642L10.038 5.85343V0H13.5108V5.85343ZM10.038 16.16H13.5108V24.0019H10.038V16.16Z"
                            fill="#43E660"
                          />
                        </svg>
                      </div>
                      <div className="text-container">
                        <p className="url-text">tr.ee/</p>
                        <p className="url-text">DB7JZRXV8x</p>
                      </div>
                    </div>
                    <div className="copy-text-container">
                      <p className="copy-text">Copy</p>
                    </div>
                  </div>
                </button>
              </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TallaModal;
