// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-picture {
    margin-bottom: 16px;
    margin-top: 70px;
  }
  
  .profile-image {
    border-radius: 50%;
    object-fit: contain;
    width: 96px;
    height: 96px;
  }
  
  .profile-title {
    display: flex;
    align-items: center;
    margin: 0 12px;
    max-width: 100%;
  }
  
  .profile-username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    color: #333; /* Cambia esto al color deseado */
  }
  
  .profile-description {
    padding: 0 24px;
    margin-top: 2px;
  }
  
  .profile-text {
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
  }
  `, "",{"version":3,"sources":["webpack://./src/templates/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,WAAW,EAAE,iCAAiC;EAChD;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":[".profile-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .profile-picture {\n    margin-bottom: 16px;\n    margin-top: 70px;\n  }\n  \n  .profile-image {\n    border-radius: 50%;\n    object-fit: contain;\n    width: 96px;\n    height: 96px;\n  }\n  \n  .profile-title {\n    display: flex;\n    align-items: center;\n    margin: 0 12px;\n    max-width: 100%;\n  }\n  \n  .profile-username {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-size: 1.125rem;\n    line-height: 1.5;\n    font-weight: 700;\n    color: #333; /* Cambia esto al color deseado */\n  }\n  \n  .profile-description {\n    padding: 0 24px;\n    margin-top: 2px;\n  }\n  \n  .profile-text {\n    text-align: center;\n    font-size: 1rem;\n    line-height: 1.5;\n    font-weight: 500;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
