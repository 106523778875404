import React from 'react';
import { useParams } from 'react-router-dom';
import TemplateMilo from '../../templates/templateMilo'; // Importa otras plantillas según sea necesario

const templates = {
  'alas.delmonte': TemplateMilo,
  // Agrega aquí otras plantillas con sus claves correspondientes
};

const DynamicTemplate = () => {
  const { username } = useParams();
  const Template = templates[username] || DefaultTemplate; // Usa una plantilla predeterminada si no se encuentra el usuario

  return (
    <div>
      <Template />
    </div>
  );
};

const DefaultTemplate = () => (
  <div style={{ padding: '20px', textAlign: 'center' }}>
    <h1>Template Not Found</h1>
    <p>The template for this user does not exist.</p>
  </div>
);

export default DynamicTemplate;
