import React, { useEffect, useState, useContext } from "react";
import "./styles.css"; // Asegúrate de importar tus estilos personalizados
import LinkComponent from "../components/LinkTemplate/Link";

const TemplateMilo = () => {
  const [imgProfile, setImgProfile] = useState(
    "https://static.wixstatic.com/media/a98e85_3a75deead9804a92b8e172c08958e337~mv2.png/v1/fill/w_82,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20ALAS%20PNG.png 1x, https://static.wixstatic.com/media/a98e85_3a75deead9804a92b8e172c08958e337~mv2.png/v1/fill/w_164,h_128,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20ALAS%20PNG.png 2x"
  );

  return (
    <div style={{background:'yellow', height:'100vh'}}>
      <div className="profile-container">
        <div className="profile-picture" id="profile-picture">
          <img className="profile-image" src={imgProfile} />
        </div>
        <div className="profile-title" id="profile-title">
          <h1 className="profile-username" id="profile-milo-bazan">
            @alas.delmonte
          </h1>
        </div>
        <div className="profile-description" id="profile-description">
          <h2 className="profile-text">TRANSPORTE ESPECIALIZADO Y CARGA GENERAL</h2>
        </div>
      </div>

      <div id="links-container">
        <LinkComponent
          href="https://www.alasdelmonte.org/"
          description="Página web"
          type="LINK"
        />

        <LinkComponent
          href="https://chat.whatsapp.com/Ee6wYloFaOI7vU199I7UiK"
          description="Buscar vacante"
          type="WAHTS_APP"
        />
      </div>
    </div>
  );
};

export default TemplateMilo;
