import React, { useState, useEffect, useRef } from "react";
import MenuBar from "../../components/menu/menuBar";
import "./styles.css";

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const interactiveImageRefs = useRef([]);

  const interactiveImages = [
    {
      images: [
        {
          src: "https://edge.website.production.linktr.ee/static/da7c1756d9a952ad6d2b501d9ec000f1/d8053/bg.png",
          alt: "Background Image",
          styles: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "auto",
            objectFit: "cover",
            transition: "opacity 0.5s ease-in-out",
          }
        },
        {
          src: "https://edge.website.production.linktr.ee/static/8fb723648ab39d080a5580c08897e6a3/db274/avatar.jpg",
          alt: "Avatar Image",
          styles: {
            position: "absolute",
            top: 0,
            left: "25%",
            width: "50%", /* Ajusta el tamaño según sea necesario */
            height: "auto",
            objectFit: "cover",
            transition: "opacity 0.5s ease-in-out",
            borderRadius: "100%",       
          }
        },
        {
          src: "https://edge.website.production.linktr.ee/static/2ab2667150b07287700abd260916c57d/b4ad8/widget.png",
          alt: "Avatar Image",
          styles: {
            paddingTop: '85%',
            marginLeft: '-70%',
            position: "absolute",
            top: 0,
            left: "25%",
            width: "80%", /* Ajusta el tamaño según sea necesario */
            height: "auto",
            objectFit: "cover",
            transition: "opacity 0.5s ease-in-out",
          }
        },
      ],
    },
    {
      images: [
        {
          src: "https://static.wixstatic.com/media/a98e85_3a75deead9804a92b8e172c08958e337~mv2.png/v1/fill/w_82,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20ALAS%20PNG.png 1x, https://static.wixstatic.com/media/a98e85_3a75deead9804a92b8e172c08958e337~mv2.png/v1/fill/w_164,h_128,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20ALAS%20PNG.png 2x",
          alt: "Dynamic Image",
        },
      ],
    },
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true); // Start transition
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % interactiveImages.length);
        setTransitioning(false); // End transition
      }, 500); // Duration of the transition effect
    }, 50000000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [interactiveImages.length]);

  useEffect(() => {
    const container = document.querySelector(".image-container");

    const handleMouseMove = (event) => {
      if (container) {
        interactiveImageRefs.current.forEach((image) => {
          if (image) {
            const containerRect = container.getBoundingClientRect();
            const mouseX = event.clientX - containerRect.left;
            const mouseY = event.clientY - containerRect.top;

            const rotateY = (mouseX / containerRect.width - 0.5) * 20; // Adjust the sensitivity
            const rotateX = (0.5 - mouseY / containerRect.height) * 20; // Adjust the sensitivity

            image.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
          }
        });
      }
    };

    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, [currentIndex]);

  const currentImages = interactiveImages[currentIndex].images;

  return (
    <div>
      <MenuBar />
      <div style={{ paddingLeft: "48px", paddingRight: "48px" }}>
        <h1 className="title">
          Una Plantilla De Dlink Que Se Adapta A Cada Marca Y Creador
        </h1>
        <div className="image-container">
          {currentImages.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={img.alt}
              ref={(el) => (interactiveImageRefs.current[index] = el)}
              style={img.styles}
              className={transitioning ? "transitioning" : ""}            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
