import React, { useEffect, useState, useContext } from "react";
import "./styles.css"; // Archivo CSS con los estilos necesarios
import TallaModal from "../modal-detail/TallaModal"; // Importa el componente de la modal
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";

const LinkComponent = ({ type, href, thumbnailSrc, description }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const menuId = "primary-search-account-menu";

  const openModal = () => {
    console.log("----entre-------");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container">
      <div
        className="link-container"
        type={type}
        style={{ background: "white" }}
      >
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="link-button"
          aria-describedby="profile-milo-bazan"
        >
          <div className="link-content">
            <div className="link-thumbnail">
              {thumbnailSrc && (
                <img
                  src={thumbnailSrc}
                  alt=""
                  className="link-thumbnail-image"
                />
              )}
            </div>
            <p className="link-description">{description}</p>
          </div>
        </a>
      </div>
      <TallaModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </div>
  );
};

export default LinkComponent;
