// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import BannerComponent from './components/banners/banner';
import Modal from 'react-modal';
import DynamicTemplate from './components/dynamicTemplate/DynamicTemplate';

Modal.setAppElement('#root');

function App() {
  return (
<div> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:username" element={<DynamicTemplate />} />
      </Routes>
    </div>
  );
}

export default App;


